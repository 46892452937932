import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer';

import "./style/App.css"

function App() {
  return (
    <div className="App">
      <Header />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
