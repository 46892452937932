import React from 'react';
import { Button } from 'react-bootstrap';
import TypeWriterEffect from 'react-typewriter-effect';

import '../style/Header.css';

const button_style = {
    position: "absolute",
    bottom: "50px",
};

class Header extends React.Component {
    render() {
        return (
            <header className="App-header">
                <TypeWriterEffect
                    textStyle={{
                        fontFamily: 'Courier New',
                        color: 'white',
                        fontWeight: 500,
                        fontSize: '1.5em',
                    }}
                    startDelay={2000}
                    cursorColor="white"
                    multiText={[
                        'Welcome.',
                        'Romulus Web',
                    ]}
                    multiTextDelay={1000}
                    typeSpeed={100}
                />
                <Button href="#begin" size="lg" variant="outline-dark" className="text-white" style={button_style}>
                    Begin
                </Button>
            </header>
        );
    }
}

export default Header;