import React from 'react';
import { Container } from 'react-bootstrap';
import '../style/Footer.css';

class Footer extends React.Component {
    render() {
        return (
            <Container fluid className="App-footer">
                Romulus Web, 2022
            </Container>
        );
    }
}

export default Footer;