import React from 'react';
import { Container } from 'react-bootstrap';
import '../style/Contact.css';

class Contact extends React.Component {
    render() {
        return (
            <Container fluid className="App-contact" id="contact">
                <h2>Contact Us</h2>
                <a href="mailto:info@romulusweb.com"><code>info@romulusweb.dev</code></a>
            </Container>
        );
    }
}

export default Contact;